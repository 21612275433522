<template>
	<div class="shared-factory">
		<div class="header acea-row row-between-wrapper" ref="header">
	      <div class="black" @click="returnChange()">
	        <img src="@assets/images/icon_black.png" alt="" />
	      </div>
	      <p>提交成功</p>
	    </div>
		<div class="order-container acea-row row-center">
			<div class="pay-done">
				<div class="success">
					<img src="@assets/images/icon_done.png" alt="" />
				</div>
				<div>
					<p class="done">打样信息提交成功！</p>
				    <p class="tips">审核通过后，我们会有专人联系您，请保持联系电话通畅</p>
				</div>
				
			</div>
		</div>
		<div class="detail-btn acea-row row-between" @click="goback()">
			<div class="right pay-done">
				<p>完成</p>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
export default  {
	name:'lithiumProtectSuccess',
	data(){
		return {
            type:this.$route.query.type,
		}
	},
	mounted(){
       
	},
	methods:{
		//返回上一页
		returnChange() {
	      var that = this;
	      that.$router.go(-1);
	    },
	    goback:function(){
	    	this.$router.push({ path: "/" });
	    }
	}
}
</script>
<style type="text/css">
.shared-factory { padding-top:50px; padding-bottom:50px; height:100% }

.shared-factory.router { background:#fff }
.shared-factory .header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.96rem;
  font-size: 0.3rem;
  color: #050505;
  background-color: #fff;
  z-index: 11;
  border-bottom: 0.01rem solid #eee;
}
.shared-factory .header .black {
  width: 7px;
  height: 14px;
  margin-left: 10px;
}
.shared-factory .header .black img {
  width: 100%;
  height: 100%;
}
.shared-factory .header p {
  width: calc(100% - 17px);
  text-align: center;
  color: #333;
}
</style>
